import { render, staticRenderFns } from "./WebApp.vue?vue&type=template&id=72928e8f&scoped=true"
import script from "./WebApp.vue?vue&type=script&lang=js"
export * from "./WebApp.vue?vue&type=script&lang=js"
import style0 from "./WebApp.vue?vue&type=style&index=0&id=72928e8f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72928e8f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreButton: require('/app/components/core/Button.vue').default})
