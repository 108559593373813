import { render, staticRenderFns } from "./TetherDeposit.vue?vue&type=template&id=aa208ec8&scoped=true"
import script from "./TetherDeposit.vue?vue&type=script&lang=js"
export * from "./TetherDeposit.vue?vue&type=script&lang=js"
import style0 from "./TetherDeposit.vue?vue&type=style&index=0&id=aa208ec8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa208ec8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSvgIcon: require('/app/components/core/SvgIcon.vue').default,CoreInputInfoField: require('/app/components/core/input/InfoField.vue').default,PublicTetherIcon: require('/app/components/public/tether/Icon.vue').default,CoreInputNumberField: require('/app/components/core/input/NumberField.vue').default,CoreInputTextField: require('/app/components/core/input/TextField.vue').default,CoreButton: require('/app/components/core/Button.vue').default})
